.bton {
  font-weight: $font-regular;
  letter-spacing: 0.5px;
  border-radius: 6.25rem;
  cursor: pointer;
  border-width: 1px;
  border-style: solid;
  font-size: 16px;

  transition: all 0.2s ease-in-out;

  &--sm {
    padding-left: 20px;
    padding-right: 20px;
    line-height: 38px;
  }
  &--md {
    padding-left: 28px;
    padding-right: 28px;
    line-height: 42px;
  }
  &--lg {
    padding-left: 36px;
    padding-right: 36px;
    line-height: 48px;
  }
  &--full {
    line-height: 48px;
    width: 100%;
  }
  &--half {
    line-height: 48px;
    width: 50%;
  }

  &--primary {
    border-color: color(primary, base);
    color: white;
    background-color: color(primary, base);
    &:hover {
      background-color: color(primary, hover);
      border-color: color(primary, hover);
      color: white;
    }
    &:active {
      border-color: color(primary, clicked);
    }
  }
  &--sec {
    border-color: color(secondary, base);
    color: white;
    background-color: color(secondary, base);
    &:hover {
      background-color: color(primary, hover);
      border-color: color(primary, hover);
      color: white;
    }
    &:active {
      border-color: color(primary, clicked);
    }
  }

  &--ghost {
    border-radius: 6.25rem;
    border-color: color(primary, base);
    color: color(primary, base);
    background-color: color(white);
    &:hover {
      background-color: color(primary, hover);
      border-color: color(primary, hover);
      color: white;
    }
    &:active {
      border-color: color(primary, clicked);
    }

    &--danger {
      border-color: color(danger, base);
      background: color(danger, tint);
      color: color(danger, base);

      &:hover {
        background-color: color(danger, base);
        border-color: color(danger, base);
        color: white;
      }
    }
  }
  &--secondary {
    border-color: white;
    color: white;
    background-color: transparent;
    &:hover {
      background: white;
      color: color(secondary, base);
    }
    &:active {
      border-color: color(secondary, active);
      color: color(secondary, active);
    }
  }
  &--google {
    border-color: #db4437;
    background-color: #db4437;
    color: white;
    &:hover {
      color: white;
      background-color: #db4437;
    }
  }
  &--apple {
    border-color: #000000;
    background-color: #000000;
    color: white;
  }
  &--facebook {
    border-color: white;
    color: white;
    background-color: #385490;
  }

  &--danger {
    background: color(danger, base);
    color: white;
    border: color(danger, base);
  }

  &--nacked {
    border: none;
    color: rgb(112, 112, 112);
    background: none;
    padding: 0;
  }
}
