.orderSuccess {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f8f9fa;
  font-family: Arial, sans-serif;
}

.orderSuccessContainer {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  max-width: 400px;
  width: 100%;
  text-align: center;
}

.orderSuccessIcon {
  width: 64px;
  height: 64px;
  margin: 0 auto 1.5rem;
  color: #28a745;
}

.orderSuccessIcon svg {
  width: 100%;
  height: 100%;
}

.orderSuccessTitle {
  font-size: 1.75rem;
  color: #28a745;
  margin-bottom: 1rem;
}

.orderSuccessMessage {
  font-size: 1rem;
  color: #495057;
  margin-bottom: 1.5rem;
  line-height: 1.5;
}

.orderSuccessEmailNotice {
  font-size: 0.9rem;
  color: #6c757d;
}

@media (max-width: 480px) {
  .orderSuccessContainer {
    padding: 1.5rem;
  }

  .orderSuccessTitle {
    font-size: 1.5rem;
  }

  .orderSuccessMessage {
    font-size: 0.9rem;
  }
}
